import { useRouter } from 'next/router';
import Index from '~/pages/index';

export default () => {
  const router = useRouter();
  const { lang } = router.query;
  if (lang !== 'en') {
    return <Index />;
  }
  return (
    <Index
      title_en="COVID-19/Coronavirus Real Time Updates With Credible Sources in US and Canada | 1Point3Acres"
      desc_en="Live updates of Coronavirus Disease 2019 (COVID-19) in U.S. and Canada. Until {time},
      there are {us} confirmed cases in US and {ca} confirmed cases in Canada."
    />
  );
}
